import { ConnectorType, ConnectorTypeMeta } from '@core/data/type/connector.type';

export enum PlatformType {
  GITHUB = 'GITHUB',
  GITLAB = 'GITLAB',
  BITBUCKET = 'BITBUCKET',
  JIRA = 'JIRA',
  CIRCLECI = 'CIRCLECI',
  GITHUB_ACTIONS = 'GITHUB_ACTIONS',
  GITLAB_PIPELINE = 'GITLAB_PIPELINE',
  SERVICE_NOW = 'SERVICE_NOW'
}

export interface PlatformTypeMeta {
  title: string;
  slug: string;
  connectorMetas: {[key: string]: ConnectorTypeMeta};
}


export const scmPlatformTypes = [
  PlatformType.GITHUB,
  PlatformType.GITLAB,
  PlatformType.BITBUCKET
];

export const taskPlatformTypes = [
  PlatformType.JIRA
];

export const cicdPlatformTypes = [
  PlatformType.CIRCLECI,
  PlatformType.GITHUB_ACTIONS,
  PlatformType.GITLAB_PIPELINE
];

export const itsmPlatformTypes = [
  PlatformType.SERVICE_NOW
];

export function platformTypeMetas(): { [key: string]: PlatformTypeMeta } {
  const tokenParameter = {
    name: 'token',
    title: 'Token',
    type: 'password',
    placeholder: 'eyJhbGciOiJIU....'
  };
  const oauth2ConnectorMeta = {
    title: 'OAuth2',
    description: 'Please allow popups for connecting via OAuth2',
    requiredParameters: []
  };

  const platformMetas = {} as { [key: string]: PlatformTypeMeta };
  platformMetas[PlatformType.GITHUB] = { title: 'GitHub', slug: 'github', connectorMetas: {} };
  platformMetas[PlatformType.GITHUB].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.GITHUB].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Personal Access Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.GITHUB_ACTIONS] = { title: 'GitHub Actions', slug: 'github-actions', connectorMetas: {} };
  platformMetas[PlatformType.GITHUB_ACTIONS].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.GITHUB_ACTIONS].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Personal Access Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.GITLAB] = { title: 'GitLab', slug: 'gitlab', connectorMetas: {} };
  platformMetas[PlatformType.GITLAB].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.GITLAB].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Personal Access Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.GITLAB_PIPELINE] = { title: 'GitLab Pipeline', slug: 'gitlab-pipeline', connectorMetas: {} };
  platformMetas[PlatformType.GITLAB_PIPELINE].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.GITLAB_PIPELINE].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Personal Access Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.BITBUCKET] = { title: 'Bitbucket', slug: 'bitbucket', connectorMetas: {} };
  platformMetas[PlatformType.BITBUCKET].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.BITBUCKET].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Access Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.JIRA] = { title: 'Jira', slug: 'jira', connectorMetas: {} };
  platformMetas[PlatformType.JIRA].connectorMetas[ConnectorType.OAUTH_2] = { ...oauth2ConnectorMeta }
  platformMetas[PlatformType.JIRA].connectorMetas[ConnectorType.BASIC_AUTH] = {
    title: 'API Token',
    description: 'Since Jira provides very restricted access for API Tokens, it is recommended to use <strong>OAuth2</strong> connection type.',
    requiredParameters: [
      {
        name: 'siteUrl',
        title: 'Site URL',
        type: 'url',
        placeholder: 'Example: https://exampe.atlassian.net'
      },
      {
        name: 'basicAuthUsername',
        title: 'E-mail',
        type: 'email',
        placeholder: 'The e-mail of the token owner account'
      },
      {
        name: 'basicAuthPassword',
        title: 'Token',
        type: 'password',
        placeholder: tokenParameter.placeholder
      }
    ]
  }

  platformMetas[PlatformType.CIRCLECI] = { title: 'CircleCI', slug: 'circleci', connectorMetas: {} };
  platformMetas[PlatformType.CIRCLECI].connectorMetas[ConnectorType.TOKEN] = {
    title: 'Personal API Token',
    requiredParameters: [tokenParameter]
  }

  platformMetas[PlatformType.SERVICE_NOW] = { title: 'ServiceNow', slug: 'service-now', connectorMetas: {} };
  platformMetas[PlatformType.SERVICE_NOW].connectorMetas[ConnectorType.TOKEN] = {
    title: 'API Token',
    description: `
    Please follow the steps outlined below for pre-integration setup: <br/>
    <ul class="list-disc list-outside mt-3 leading-6">
      <li>Create a new user account that will act like a service account and mark it as <strong>Internal Integration User</strong>.</li>
      <li>Assign the following roles to the service account:
        <ul class="list-disc list-outside ml-3">
          <li><strong>business_rule_admin</strong> (required for creating webhooks by using Business Rules)</li>
          <li><strong>web_service_admin</strong> (required for creating REST Message as a part of Business Rule webhooks)</li>
          <li><strong>itil</strong> (required for accessing Incidents via Table API)</li>
        </ul>
      </li>
      <li>Create an API Token following the instructions given <a href="https://docs.servicenow.com/bundle/washingtondc-platform-security/page/integrate/authentication/task/configure-api-key.html" target="_blank"><strong>here</strong></a></li>
    </ul>
    `,
    requiredParameters: [
      {
        name: 'siteUrl',
        title: 'Instance URL',
        type: 'url',
        placeholder: 'Example: https://dev123456.service-now.com'
      },
      {
        name: 'token',
        title: 'API Token',
        type: 'password',
        placeholder: tokenParameter.placeholder
      }
    ]
  }

  platformMetas[PlatformType.SERVICE_NOW].connectorMetas[ConnectorType.BASIC_AUTH] = {
    title: 'Username & Password',
    description: `
    Please follow the steps outlined below for pre-integration setup: <br/>
    <ul class="list-disc list-outside mt-3 leading-6">
      <li>Create a new user account that will act like a service account and mark it as <strong>Internal Integration User</strong>.</li>
      <li>Set a password for the service account.</li>
      <li>Assign the following roles to the service account:
        <ul class="list-disc list-outide ml-3">
          <li><strong>business_rule_admin</strong> (required for creating webhooks)</li>
          <li><strong>web_service_admin</strong> (required for creating REST Message to use in a Business Rule as webhook)</li>
          <li><strong>itil</strong> (required for accessing Incidents via Table API)</li>
        </ul>
      </li>
    </ul>
    `,
    requiredParameters: [
      {
        name: 'siteUrl',
        title: 'Instance URL',
        type: 'url',
        placeholder: 'Example: https://dev123456.service-now.com'
      },
      {
        name: 'basicAuthUsername',
        title: 'User ID',
        type: 'email',
        placeholder: 'The username of the service account'
      },
      {
        name: 'basicAuthPassword',
        title: 'Password',
        type: 'password',
        placeholder: tokenParameter.placeholder
      }
    ]
  }

  return platformMetas;
}

export function isScmPlatform(platformType: PlatformType) {
  return scmPlatformTypes.includes(platformType);
}

export function isTaskPlatform(platformType: PlatformType) {
  return taskPlatformTypes.includes(platformType);
}

export function isCicdPlatform(platformType: PlatformType) {
  return cicdPlatformTypes.includes(platformType);
}

export function isItsmPlatform(platformType: PlatformType) {
  return itsmPlatformTypes.includes(platformType);
}
